import { Injectable, NgZone } from '@angular/core';
import { User } from './interfaces/user';
// import { UserService } from '../../tiptunes/user.service';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
// import * as auth from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userData: any; // Firebase Authentication Reference record
  userProfile: any; // User Profile data
  userId: string;
  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    // public userService: UserService,
  ) {

    this.userId = null;
    this.userData = null;
    try {
      let previousUserData = JSON.parse(localStorage.getItem('user'));
      if (previousUserData) {
        this.userId = previousUserData.uid;
        this.userData = previousUserData;
      }
    }
    catch {
      console.info('brand new user, there is no previous data to default to');
    }

    /* Saving user data in localstorage when 
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.userId = user.uid;
        this.userData = user;
        // this.userService.refreshUserProfile(this.userId);
        localStorage.setItem('user', JSON.stringify(this.userData));
      } else {
        this.userId = null;
        localStorage.setItem('user', 'null');
      }
    });
  }
  // Sign in with email/password
  LogIn(email: string, password: string) {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((result: any) => {
        this.afAuth.authState.subscribe((user) => {
          if (user) {
            this.router.navigate(['dashboard']);
          }
        });
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }
  // Sign up with email/password
  SignUp(displayName: string, email: string, password: string, username: string) {
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then(async (userCredential) => {

        const user = userCredential.user;
        const newUser = {
          // ...user,
          uid: user.uid,
          displayName: displayName,
          username: username,
          email: email,
          emailVerified: user.emailVerified,
          metadata: JSON.parse(JSON.stringify(user.metadata)),
          phone: user.phoneNumber,
          facebookLink: '',
          instagramLink: '',
          tiktokLink: '',
          venmoLink: '',
          webLink: '',
          xLink: '',
        };

        this.userData = newUser;
        await this.afs.doc(`users/${user.uid}`).set(newUser, { merge: true });
        await localStorage.setItem('profile', JSON.stringify(newUser));
        await userCredential.user.updateProfile({ displayName: displayName }).then(() => {
          /* Call the SendVerificaitonMail() function when new user sign 
          up and returns promise */
          this.SendVerificationMail();
        })

        this.router.navigate(['tiptunes', 'performer', 'dashboard']);
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }
  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.currentUser
      .then((u: any) => u.sendEmailVerification())
  }
  // Reset Forggot password
  ForgotPassword(passwordResetEmail: string) {
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      })
      .catch((error) => {
        window.alert(error);
      });
  }
  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user')!);
    return (user !== null) && (user.hasOwnProperty('uid'));
  }

  /* 
    Setting up user data when sign in with username/password, 
    sign up with username/password and sign in with social auth  
    provider in Firestore database using AngularFirestore + AngularFirestoreDocument service 
  */
  SetUserData(user: User) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );
    localStorage.setItem('user', JSON.stringify(user));
    return userRef.set(user, {
      merge: true,
    });
  }
  GetUserId(): string {
    return this.userId;
  }
  GetUserData(): User {
    return this.userData;
  }
  // Sign out
  LogOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      localStorage.removeItem('profile');
      this.router.navigate(['/auth/login']);
    });
  }
  getUserRole() {
    const user = JSON.parse(localStorage.getItem('user')!);
    return user?.role ?? '';
  }
}