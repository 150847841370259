import { Injectable, NgZone } from '@angular/core';
import { User } from './interfaces/user';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { Gig } from '../../tiptunes/performer/gigs/models/gig';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userData: any; // Firebase Authentication Reference record
  userProfile: any; // User Profile data
  userId: string;

  template_user_id: string = 'JYMFUgkEAEPXuMV7wE1FA8pQqXw2';
  template_gig_id: string = '81eef097-9619-48ff-a83d-e8a0a3c9eaa9';

  constructor(
    public firestore: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    // public userService: UserService,
  ) {

    this.userId = null;
    this.userData = null;
    try {
      let previousUserData = JSON.parse(localStorage.getItem('user'));
      if (previousUserData) {
        this.userId = previousUserData.uid;
        this.userData = previousUserData;
      }
    }
    catch {
      console.info('brand new user, there is no previous data to default to');
    }

    /* Saving user data in localstorage when 
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.userId = user.uid;
        this.userData = user;
        // this.userService.refreshUserProfile(this.userId);
        localStorage.setItem('user', JSON.stringify(this.userData));
      } else {
        this.userId = null;
        localStorage.setItem('user', 'null');
      }
    });
  }
  // Sign in with email/password
  LogIn(email: string, password: string) {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((result: any) => {
        this.afAuth.authState.subscribe((user) => {
          if (user) {
            this.router.navigate(['dashboard']);
          }
        });
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }
  // Sign up with email/password
  SignUp(displayName: string, email: string, password: string, username: string) {
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then(async (userCredential) => {

        const user = userCredential.user;
        const newUser = {
          // ...user,
          uid: user.uid,
          displayName: displayName,
          username: username,
          email: email,
          emailVerified: user.emailVerified,
          metadata: JSON.parse(JSON.stringify(user.metadata)),
          phone: user.phoneNumber,
          facebookLink: '',
          instagramLink: '',
          tiktokLink: '',
          venmoLink: '',
          webLink: '',
          xLink: '',
        };

        this.userData = newUser;
        await this.firestore.doc(`users/${user.uid}`).set(newUser, { merge: true });
        await localStorage.setItem('profile', JSON.stringify(newUser));
        await userCredential.user.updateProfile({ displayName: displayName }).then(() => {
          /* Call the SendVerificaitonMail() function when new user sign 
          up and rewhereturns promise */
          //this.SendVerificationMail();
        })

        this.createExampleGig();

        this.router.navigate(['tiptunes', 'performer', 'dashboard']);
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }
  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.currentUser
      .then((u: any) => u.sendEmailVerification())
  }
  // Reset Forggot password
  ForgotPassword(passwordResetEmail: string) {
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      })
      .catch((error) => {
        window.alert(error);
      });
  }

  // Reset Forggot password
  ResetPassword(password: string, oobCode: string) {
    this.afAuth.confirmPasswordReset(oobCode, password)
      .then(() => {
        this.router.navigate(['/tiptunes'])
      })
      .catch((error) => {
        window.alert(error)
      })
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user')!);
    return (user !== null) && (user.hasOwnProperty('uid'));
  }

  /* 
    Setting up user data when sign in with username/password, 
    sign up with username/password and sign in with social auth  
    provider in Firestore database using AngularFirestore + AngularFirestoreDocument service 
  */
  SetUserData(user: User) {
    const userRef: AngularFirestoreDocument<any> = this.firestore.doc(
      `users/${user.uid}`
    );
    localStorage.setItem('user', JSON.stringify(user));
    return userRef.set(user, {
      merge: true,
    });
  }
  GetUserId(): string {
    return this.userId;
  }
  GetUserData(): User {
    return this.userData;
  }
  // Sign out
  LogOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      localStorage.removeItem('profile');
      this.router.navigate(['/auth/login']);
    });
  }
  getUserRole() {
    const user = JSON.parse(localStorage.getItem('user')!);
    return user?.role ?? '';
  }

  async createExampleGig() {
    // Clone the default template user details for this newly registered user
    const exampleGigRef: any = await this.firestore.doc(`gigs/${this.template_gig_id}`).ref.get();
    const exampleGig: any = exampleGigRef.data();

    const performerExampleGigRef = await this.firestore.collection(`gigs`).ref
      .where('owner_id', '==', this.userId)
      .where('name', '==', 'Example Gig')
      .get();

    let exampleGigId = ''
    if (performerExampleGigRef.docs.length > 0) {
      exampleGigId = performerExampleGigRef.docs[0].id;
    } else {
      exampleGigId = this.firestore.createId();
    }

    // Update the details of the gig so it's for the new user
    exampleGig.id = exampleGigId;
    exampleGig.owner_id = this.userId;
    exampleGig.schedule.start = new Date();
    exampleGig.schedule.end = moment().add('d', 7).toDate();

    // Create an example songlist
    let songlistId = '';
    const exampleSonglistRef = await this.firestore.collection(`performer_songlists`).ref
      .where('owner_id', '==', this.userId)
      .where('name', '==', 'Example Songlist')
      .get();
    if (exampleSonglistRef.docs.length > 0) {
      songlistId = exampleSonglistRef.docs[0].id;
    } else {
      songlistId = this.firestore.createId();
      this.firestore.doc(`performer_songlists/${songlistId}`).set({
        id: songlistId,
        description: 'This is an example songlist',
        name: 'Example Songlist',
        owner_id: this.userId,
        type: 'songlist',
      });
    }

    // Get the Songs from the gig catalog and add them to the performer song list
    exampleGig.catalog.forEach(async song => {
      let exampleSongRef = await this.firestore.collection('performer_songs').ref
        .where('owner_id', '==', this.userId)
        .where('song_name', '==', song.song_name)
        .where('artist_name', '==', song.artist_name)
        .get();

      let song_id = '';
      if (exampleSongRef.docs.length > 0) {
        song_id = exampleSongRef.docs[0].id;
      } else {
        song_id = this.firestore.createId();

      }
      song.owner_id = this.userId;
      song.id = song_id;
      song.songlists = {
        [songlistId]: true
      }
      this.firestore.doc(`performer_songs/${song.id}`).set(song, { merge: true });
    });

    // Save the Example Gig
    exampleGig.songlist_id = songlistId;
    this.firestore.doc(`gigs/${exampleGig.id}`).set(exampleGig, { merge: true });

  }
}